import GlobalLayout from "components/page/GlobalLayout";
import PricingPage from "components/pricing/PricingPage";
import React from "react";

export default function AcessControlPricing() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <PricingPage product="access control" />
    </GlobalLayout>
  );
}
